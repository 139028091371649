import React, { useState } from 'react';
import { withFirebase } from '../Firebase';
import { useFormik } from 'formik';
import * as Yup from 'yup';
//import Button from "@material-ui/core/Button"
import { Link } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

/*
    INTL (i18n) docs:
    https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
  */

/*
    Formik+YUP:
    https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  */
const bgURL =
  'https://firebasestorage.googleapis.com/v0/b/uride-prod.appspot.com/o/background-images%2FbookingBG.webp?alt=media&token=4e537769-66da-42fa-8a1c-97fef9e0c8f6';

const titleURL =
  'https://firebasestorage.googleapis.com/v0/b/uride-prod.appspot.com/o/background-images%2FEvent%20Title2.png?alt=media&token=e9981972-17a1-486a-b632-f1cff2a9dda5';

const logoURL =
  'https://firebasestorage.googleapis.com/v0/b/uride-prod.appspot.com/o/background-images%2FNew%20Logo.png?alt=media&token=f47c7528-a341-4129-8e9b-fd49cbdeddf1';

const initialValues = {
  email: '',
};

function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('E-mail required'),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, resetForm }) => {
      console.log({ firebase: props.firebase, values });
      props.firebase
        .doPasswordReset(values.email)
        .then((r) => {
          setLoginError('');
          setSuccessMessage('Email sent to reset password');
          resetForm();
        })
        .catch((error) => {
          setSuccessMessage('');
          setLoginError(error.message);
          resetForm();
        });
    },
  });

  return (
    <>
      <div
        className="d-flex justify-content-end"
        style={{
          height: '100vh',
          backgroundImage: `url(${bgURL})`,

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center bottom',
        }}
      >
        <div style={{}}>
          <img
            id="event-title"
            style={{}}
            src={titleURL}
            alt="title"
          />
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: '1',
            float: 'right',
            bottom: '0',
          }}
        >
          <img style={{width: "250px", margin: "15px"}} src={logoURL} alt="logo" />
        </div>
        <div
          className="login-form"
          className="login-form login-signin"
          id="kt_login_signin_form"
        >
          {/*begin::Form*/}
          <form
            style={{ padding: '8%', marginTop: '26%' }}
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            <div
              className="form-group fv-plugins-icon-container"
              style={{ opacity: '1' }}
            >
              <label for="email">
                <b>E-mail address</b>
              </label>
              <input
                placeholder="Email"
                type="email"
                id="email"
                className={`form-control h-auto form-control-solid py-3 px-6 ${getInputClasses(
                  'email',
                )}`}
                name="email"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.email}
                  </div>
                </div>
              ) : null}
            </div>
            {loginError && (
              <span style={{ color: 'red' }}>{loginError}</span>
            )}

            {successMessage && (
              <span style={{ color: '#20B2AA' }}>{successMessage}</span>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10%',
              }}
            >
              <button
                id="kt_login_signin_submit"
                type="submit"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={formik.isSubmitting}
                className={`btn font-weight-bold px-8 py-2 my-3`}
                style={{
                  backgroundColor: '#1BC5BD',
                  opacity: '0.99',
                }}
              >
                <span style={{ color: 'black' }}>
                  <strong>Reset</strong>
                </span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <Link
                to="/"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="kt_login_forgot"
              >
                Back
              </Link>
            </div>
          </form>
     
        </div>
      </div>
    </>
  );
}



const FgPassword = withFirebase(ForgotPassword);


export default FgPassword;
